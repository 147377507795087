<template>
    <div>
        <div v-if="params.componentName == 'Actuality'">
            <b-button v-b-tooltip.hover :title="params.tooltip" v-if="params.iconType == 'feather'"
                :variant="params.variant" class="btn-icon" size="sm" style=" padding: 5px;"
                @click="params.onButtonClick(params.data)">
                <feather-icon :icon="params.icon" size="15" />
            </b-button>
            <b-button v-b-tooltip.hover :title="params.tooltip" v-else
                :variant="params.variant ? params.variant : 'outline-secondary'" class="btn-icon" size="sm"
                style=" padding: 0px;" @click="params.onButtonClick(params.data)">
                <i class="material-icons" style="width: 35px;height:0px">{{ params.icon }}</i>
            </b-button>
        </div>
        <div v-else-if="params.componentName == 'NewOrder'">
            <div v-if="params.data.code != ''">
                <b-button v-b-tooltip.hover :title="params.tooltip" v-if="params.iconType == 'feather'"
                    :variant="params.variant" class="btn-icon" size="sm" style=" padding: 5px;"
                    @click="params.onButtonClick(params.data)">
                    <feather-icon :icon="params.icon" size="15" />
                </b-button>
                <b-button v-b-tooltip.hover :title="params.tooltip" v-else
                    :variant="params.variant ? params.variant : 'outline-secondary'" class="btn-icon" size="sm"
                    style=" padding: 0px;" @click="params.onButtonClick(params.data)">
                    <i class="material-icons" style="width: 35px;height:0px">{{ params.icon }}</i>
                </b-button>
            </div>
        </div>
        <div v-else-if="params.componentName == 'ProductList'">
            <b-button v-b-tooltip.hover :title="params.tooltip" variant="flat-success" class="btn-icon mr-50" size="sm"
                style=" padding: 0px;" @click="params.onAddClick(params.data)">
                <feather-icon icon="DownloadIcon" size="22" />
            </b-button>
            <b-button v-b-tooltip.hover :title="params.tooltip" variant="flat-secondary" class="btn-icon" size="sm"
                style=" padding: 0px;" @click="params.onButtonClick(params.data)">
                <feather-icon icon="FileTextIcon" size="22" />
            </b-button>
        </div>
        <div v-else>
            <b-button v-b-tooltip.hover :title="params.tooltip" v-if="params.iconType == 'feather'"
                :variant="params.variant" class="btn-icon" size="sm" style=" padding: 5px;"
                @click="params.onButtonClick(params.data)">
                <feather-icon :icon="params.icon" size="15" />
            </b-button>
            <b-button v-b-tooltip.hover :title="params.tooltip" v-else
                :variant="params.variant ? params.variant : 'outline-secondary'" class="btn-icon" size="sm"
                style=" padding: 0px;" @click="params.onButtonClick(params.data)">
                <i class="material-icons" style="width: 35px;height:0px">{{ params.icon }}</i>
            </b-button>
        </div>
    </div>
</template>
  
<script>

export default {
};
</script>
  
<style scoped></style>
  