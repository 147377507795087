<template>
    <div id="administrator-claims">

        <b-modal hide-footer ref="reclamation_details" title="Détail Réclamation" cancel-variant="outline-secondary">

            <b-row>
                <b-col cols="12" md="12">
                    <b-form-group>
                        <label style="font-weight:bold" for="Type">Type réclamation</label>
                        <b-card-text label="Type" name="Type">{{ reclamation.type }}</b-card-text>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="12">
                    <b-form-group>
                        <label style="font-weight:bold">Message</label>
                        <b-form-textarea id="textarea-state" readonly :placeholder="reclamation.description" rows="0.5" />
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="12">
                    <b-form-group>
                        <label style="font-weight:bold">Réponse</label>
                        <b-form-textarea required id="textarea-state" v-model="reclamation.response"
                            :state="reclamation.response.length <= 250" placeholder="Enter only 250 characters"
                            rows="0.5" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="text-right">

                <b-col cols="12" md="12">
                    <b-button @click="annuler" variant="gradient-warning" style="margin-right:5px">
                        Annuler
                    </b-button>
                    <b-button @click="envoyer" :disabled="this.reclamation.state || this.reclamation.response.length == 0"
                        variant="gradient-success">
                        Repondre
                    </b-button>
                </b-col>
            </b-row>

        </b-modal>

        <b-overlay :show="showLoading" spinner-variant="primary">
            <b-card>
                <ag-grid :agGrid="agGrid" pageTitle="Liste des réclamations" @refresh="loadData" />
            </b-card>
        </b-overlay>
    </div>
</template>

<script>

import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import BadgeCellRenderer from "../components/ag-grid/BadgeCellRenderer.vue";
import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"

export default {
    components: {
        AgGrid,
    },
    data() {
        return {
            reclamation: {
                id: -1,
                response: ''
            },
            showLoading: false,
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Date",
                        field: "createdAt",
                        width: 60
                    },
                    {
                        headerName: "Type",
                        field: "type",
                        width: 80,
                    },
                    {
                        headerName: "Réclamation",
                        field: "description",
                        width: 200
                    },
                    {
                        headerName: "",
                        field: "state",
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'badgeCellRenderer',
                        cellRendererParams: { componentName: "Claims" },
                        width: 30
                    },
                    {
                        sortable: false,
                        resizable: false,
                        filter: false,
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'buttonCellRenderer',
                        cellRendererParams: { onButtonClick: this.showModal, icon: "read_more" },
                        headerName: '',
                        width: 30
                    },
                ],
                rows: []
            },
        }
    },
    created() {
        this.$options.components.badgeCellRenderer = BadgeCellRenderer;
        this.$options.components.buttonCellRenderer = ButtonCellRenderer;
        this.loadData();
    },
    methods: {
        showModal(data) {
            this.reclamation = data
            if (this.reclamation.response == null) {
                this.reclamation.response = ""
            }
            this.$refs.reclamation_details.show();
        },
        async envoyer() {
            this.$refs['reclamation_details'].hide();
            this.showLoading = true;
            await this.$http.post('claim', this.reclamation).catch(() => this.showLoading = false);
            this.loadData();
            this.$swal({
                title: 'Réclamtion bien traitée!',
                text: 'Le client sera notifié de votre réponse.',
                icon: 'success',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            });
        },
        annuler() {
            this.$refs['reclamation_details'].hide();
        },
        async loadData() {
            this.showLoading = true;
            let response = await this.$http.get("claim");
            this.agGrid.rows = response.data;
            this.showLoading = false;
        }
    }
}
</script>

<style lang="scss"></style>
