<template>
    <div id="badge-component">
        <!-- CustomerList -->
        <div v-if="params.componentName == 'CustomerList'">
            <div v-if="params.columnName == 'Portal_Status'">
                <b-badge variant="success" v-if="params.data.Portal_Status == 'Active'">
                    <feather-icon icon="UserCheckIcon" class="mr-25" />
                    <span>{{ params.data.Portal_Status }}</span>
                </b-badge>
                <b-badge variant="danger" v-else-if="params.data.Portal_Status == 'Not Active'">
                    <feather-icon icon="UserXIcon" class="mr-25" />
                    <span>{{ params.data.Portal_Status }}</span>
                </b-badge>
                <b-badge v-else>
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>Dormant</span>
                </b-badge>
            </div>
        </div>
        <!-- CustomerList -->

        <!-- ProductsList -->
        <div v-if="params.componentName == 'ProductsList'">
            <div v-if="params.columnName == 'Type'">
                <b-badge variant="success" v-if="params.data.type == 'MED'">
                    <span>{{ params.data.type }}</span>
                </b-badge>
                <b-badge variant="primary" v-else-if="params.data.type == 'PARA'">
                    <span>{{ params.data.type }}</span>
                </b-badge>
                <b-badge variant="secondary" v-else>
                    <span>{{ params.data.type }}</span>
                </b-badge>
            </div>
        </div>
        <!-- ProductsList -->

        <!-- Actuality -->
        <div v-if="params.componentName == 'Actuality'">
            <div v-if="params.columnName == 'Family'">
                <b-badge variant="success" v-if="params.data.Gen_Prod_Posting_Group == 'MEDICAMENT'">
                    <span>{{ params.data.Gen_Prod_Posting_Group }}</span>
                </b-badge>
                <b-badge variant="primary" v-else-if="params.data.Gen_Prod_Posting_Group == 'PARA'">
                    <span>{{ params.data.Gen_Prod_Posting_Group }}</span>
                </b-badge>
                <b-badge variant="warning" v-else-if="params.data.Gen_Prod_Posting_Group == 'ACCESSOIRE'">
                    <span>{{ params.data.Gen_Prod_Posting_Group }}</span>
                </b-badge>
                <b-badge variant="secondary" v-else>
                    <span>{{ params.data.Gen_Prod_Posting_Group }}</span>
                </b-badge>
            </div>
        </div>
        <!-- Actuality -->

        <!-- Orders -->
        <div v-if="params.componentName == 'Orders' || params.componentName == 'Shipments'">
            <div v-if="params.columnName == 'Gen_Prod_Posting_Group'">
                <b-badge variant="success" v-if="params.data.Gen_Prod_Posting_Group == 'MEDICAMENT'">
                    <span>{{ params.data.Gen_Prod_Posting_Group }}</span>
                </b-badge>
                <b-badge variant="primary" v-else-if="params.data.Gen_Prod_Posting_Group == 'PARA'">
                    <span>{{ params.data.Gen_Prod_Posting_Group }}</span>
                </b-badge>
                <b-badge variant="warning" v-else-if="params.data.Gen_Prod_Posting_Group == 'ACCESSOIRE'">
                    <span>{{ params.data.Gen_Prod_Posting_Group }}</span>
                </b-badge>
                <b-badge variant="secondary" v-else>
                    <span>{{ params.data.Gen_Prod_Posting_Group }}</span>
                </b-badge>
            </div>
            <div v-if="params.columnName == 'Order_Status'">
                <b-badge variant="success" v-if="params.data.Order_Status == 'Shipped'">
                    <span>Expédié</span>
                </b-badge>
                <b-badge variant="info" v-else-if="params.data.Order_Status == 'Awaiting shipment'">
                    <span>En attente d'expédition</span>
                </b-badge>
                <b-badge variant="primary" v-else-if="params.data.Order_Status == 'Awaiting preparation'">
                    <span>En attente de préparation</span>
                </b-badge>
                <b-badge variant="secondary" v-else>
                    <span>{{ params.data.Order_Status }}</span>
                </b-badge>
            </div>
        </div>
        <!-- Orders -->

        <!-- Claims -->
        <div v-if="params.componentName == 'Claims'">
            <!-- <b-badge bill variant="warning" v-if="params.data.Statut == 'Open'">
                {{ params.data.Statut }}
            </b-badge>
            <b-badge bill variant="danger" v-else>
                {{ params.data.Statut }}
            </b-badge> -->
            <b-badge bill variant="success" v-if="params.data.Statut == 'Closed'">
                <feather-icon size="20" icon="CheckIcon" />
            </b-badge>
            <b-badge bill variant="warning" v-else>
                <feather-icon size="20" icon="LoaderIcon" />
            </b-badge>
        </div>
        <!-- Claims -->

        <!-- NewOrder -->
        <div v-if="params.componentName == 'NewOrder'">
            <b-badge bill :variant="params.data.promotion.includes('-') ? 'warning' : 'success'"
                v-if="params.columnName == 'promotion' && params.data.promotion">
                {{ params.data.promotion }}
            </b-badge>
            <!-- <b-badge bill variant="primary" v-if="params.columnName == 'total'">
                {{ params.data.total }}
            </b-badge> -->
        </div>
        <!-- NewOrder -->

        <!-- Users -->
        <div v-if="params.componentName == 'Users'">
            <b-badge bill variant="success" v-if="params.data.activated">
                actif
            </b-badge>
            <b-badge bill variant="danger" v-else>
                inactif
            </b-badge>
        </div>
        <!-- Users -->

        <!-- CustomerByCompany -->
        <div v-if="params.componentName == 'CustomerByCompany'" style="cursor:pointer;">
            <b-badge bill variant="success" v-if="params.data.affected">
                Affected
            </b-badge>
            <b-badge @click="params.onButtonClick(params.data)" bill variant="warning" v-else>
                Not Affected
            </b-badge>
        </div>
        <!-- CustomerByCompany -->

    </div>
</template>
  
<script>

export default {
    methods: {
    },
    created() {
        // console.log(this.params);
    }
};
</script>
  
<style scoped></style>
  